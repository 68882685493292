import React from 'react'
import {
  IResourceComponentsProps,
  HttpError,
  BaseRecord,
} from '@refinedev/core'
import { useTable, List, EditButton } from '@refinedev/antd'
import { Space, Table } from 'antd'
import { RegionsRoDto } from '@/services/data-contracts'

export const RegionsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<RegionsRoDto, HttpError>()

  return (
    <List title="Список Регионов">
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Название" />
        <Table.Column dataIndex="key" title="Ключ" />
        <Table.Column
          dataIndex="popular"
          title="Популярность"
          render={(value: boolean) => (value ? 'Да' : 'Нет')}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
