import React from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  useGetIdentity,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  EmailField,
} from '@refinedev/antd'
import { Table, Space } from 'antd'
import { Roles } from './enums'
import { RegionRoDto, UserRoDto } from '@/services/data-contracts'

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity<UserRoDto>()
  const isAdmin = user?.roles === 6

  const { tableProps } = useTable({})

  return (
    <List
      canCreate={isAdmin}
      title="Список Пользователей"
      createButtonProps={{ children: 'Создать' }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column
          dataIndex={['email']}
          title="Почта"
          render={(value: any) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex="roles"
          title="Роль"
          render={(value: keyof typeof Roles) => Roles[value]}
        />
        <Table.Column
          dataIndex="region"
          title="Регион"
          render={(value: RegionRoDto) => value.name}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {isAdmin && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {isAdmin && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
