import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import {
  Form,
  Input,
  Upload,
  Typography,
  Select,
  Button,
  Card,
  Space,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  EventRo,
  OrganizationsCategoryRo,
  OrganizationsDto,
  RegionRoDto,
} from '@/services/data-contracts'
import { UploadChangeParam } from 'antd/es/upload'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'

const { Title } = Typography

type OrganizationCreateObj = {
  contacts?: string[]
  description: string
  events?: number[]
  metaDescription?: string
  metaKeywords?: string
  metaTitle?: string
  name: string
  preview?: UploadChangeParam
  regions?: number[]
  categories?: number[]
  typeActivity: string
}

export const OrganizationsCreate: React.FC<IResourceComponentsProps> = () => {
  const { onFinish, formProps, saveButtonProps } = useForm<OrganizationsDto>()

  const { selectProps: selectRegions } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const { selectProps: selectCategories } = useSelect<OrganizationsCategoryRo>({
    resource: 'organizations-categories',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const { selectProps: selectEvents } = useSelect<EventRo>({
    resource: 'events/select',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const handleOnFinish = (values: any) => {
    const formValues = values as OrganizationCreateObj

    onFinish({
      ...values,
      events: formValues.events?.map(event => ({ id: event })),
      regions: formValues.regions?.map(event => ({ id: event })),
      categories: formValues.categories?.map(category => ({ id: category })),
      preview: formValues.preview?.fileList.map((file: any) => ({
        id: file.response.id,
      }))[0],
    } as OrganizationsDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Создание Организации">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Организации',
            },
          ]}
        >
          <Input placeholder="Например: Яркие События" />
        </Form.Item>
        <Form.Item
          label="Род деятельности"
          name={['typeActivity']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите род деятельности Организации',
            },
          ]}
        >
          <Input placeholder="Например: Event-агентство полного цикла" />
        </Form.Item>
        <Form.Item label="Описание" name={['description']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>
        <Form.Item label="Превью организации" name="preview">
          <Upload
            maxCount={1}
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            onPreview={file => window.open(file?.response?.location)}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Категории"
          name={['categories']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectCategories}
            showSearch={false}
            mode="multiple"
            placeholder="..."
          />
        </Form.Item>

        <Form.Item
          label="Регион"
          name={['regions']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectRegions}
            showSearch={false}
            mode="multiple"
            placeholder="..."
          />
        </Form.Item>

        <Form.Item
          label="Мероприятия"
          name={['events']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select {...selectEvents} mode="multiple" placeholder="..." />
        </Form.Item>

        <Form.List
          name="contacts"
          initialValue={['']}
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 1 contacts'))
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 ? 'Контактная информация' : ''}
                  required={true}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          'Пожалуйста, введите контактную информацию или удалите это поле',
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="+7 (999) 999-99-99 | mail@mail.ru | www.site.ru"
                      style={{ width: '60%' }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Добавить Контактную информацию
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Социальные сети</Title>

          <Form.List name="socials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      style={{ width: 100 }}
                      name={[name, 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: 'VK', value: 'vk' },
                          { label: 'Telegram', value: 'tg' },
                          { label: 'OK', value: 'ok' },
                          { label: 'YouTUbe', value: 'youtube' },
                          { label: 'WWW', value: 'web' },
                        ]}
                        placeholder="Тип социальной сети"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: 300 }}
                      name={[name, 'link']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Ссылка" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
