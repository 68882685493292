export const isAntUpload = (upload: any) => {
  return Object.keys(upload).some(key => ['fileList'].includes(key))
}

export const previewAnt = (preview: any) =>
  preview
    ? isAntUpload(preview)
      ? preview.fileList
      : Array.isArray(preview)
        ? preview.map(preview => ({
            uid: String(preview?.id),
            name: '',
            url: preview?.location,
          }))
        : [
            {
              uid: String(preview?.id),
              name: '',
              url: preview?.location,
            },
          ]
    : []

export const previewAntUrl = (preview: any) =>
  preview
    ? isAntUpload(preview)
      ? preview.fileList
      : preview
        ? [
            {
              uid: ' -1',
              name: '',
              url: preview,
            },
          ]
        : []
    : []
