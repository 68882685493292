import React, { useCallback, useEffect, useState } from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  useSelect,
  HttpError,
  LogicalFilter,
  useGetIdentity,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space, Select, Flex, Input } from 'antd'
import { EventsDataRo, RegionRoDto, UserRoDto } from '@/services/data-contracts'
import { format } from 'date-fns'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from '@/scripts/debounce'

export const EventsList: React.FC<IResourceComponentsProps> = () => {
  const [inputText, setInputText] = useState<string>()
  const debounced = useDebounce(inputText)

  const { data: user } = useGetIdentity<UserRoDto>()
  const isAdmin = user?.roles === 6

  const { tableProps, filters, setFilters } = useTable<
    EventsDataRo,
    HttpError
  >()

  useEffect(() => {
    if (!isAdmin && user?.region.key) {
      setFilters([
        {
          field: 'regions',
          operator: 'eq',
          value: [user.region.key],
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, user?.region.key])

  const { ...selectProps } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'key',
  })

  const regionFilter = (filters as LogicalFilter[]).find(
    filter => filter.field === 'region',
  )?.value

  const currentSelect = selectProps.options?.find(
    ({ value }) => value === (regionFilter || 'msk'),
  )

  useEffect(() => {
    if (debounced !== undefined) {
      setFilters(
        [
          {
            field: 'name',
            operator: 'eq',
            value: debounced?.trim(),
          },
        ],
        'merge',
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  useEffect(() => {
    const input = (filters as LogicalFilter[]).find(
      filter => filter.field === 'name',
    )?.value

    setInputText(input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchHandler = useCallback(props => {
    setInputText(props.target.value)
  }, [])

  return (
    <List
      title="Список Мероприятий"
      createButtonProps={{ children: 'Создать' }}
    >
      <Flex gap="middle" style={{ marginBottom: 20 }}>
        {isAdmin && (
          <Select
            placeholder="Регион"
            style={{ width: 300 }}
            value={currentSelect}
            onChange={value => {
              setFilters(
                [
                  {
                    field: 'region',
                    operator: 'eq',
                    value,
                  },
                ],
                'merge',
              )
            }}
            {...selectProps}
          />
        )}
        <Input
          value={inputText}
          addonAfter={<SearchOutlined />}
          onChange={searchHandler}
          placeholder="Название Мероприятия"
        />
      </Flex>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Название" />
        <Table.Column
          dataIndex="createdAt"
          title="Дата создания/синхронизации"
          render={value => format(new Date(value), 'dd.MM.yyyy HH:mm:ss')}
        />
        <Table.Column
          dataIndex="region"
          title="Регион"
          render={(value: RegionRoDto) => `${value.name}`}
        />
        <Table.Column
          dataIndex="deletedAt"
          title="Состояние"
          render={(value?: Date) => (value ? 'Удалено' : 'Опубликовано')}
        />

        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => {
            const isDeleted = record?.deletedAt

            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
                {!isDeleted && (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                )}
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
