import { DingdingOutlined } from '@ant-design/icons'
import { AuthPage } from '@refinedev/antd'
import { Typography } from 'antd'

const { Title } = Typography

export const Login = () => {
  return (
    <AuthPage
      type="login"
      title={
        <Title>
          <DingdingOutlined /> Свободное время
        </Title>
      }
      forgotPasswordLink=""
      registerLink=""
      rememberMe={false}
    />
  )
}
