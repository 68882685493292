import { AuthBindings } from '@refinedev/core'
import type { LoginRoDto, UserRoDto } from '@/services/data-contracts'
import { axiosInstance } from './axios'

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    if (email && password) {
      const auth = await axiosInstance.post<LoginRoDto>(
        `${process.env.API_URL}/auth/login`,
        {
          email,
          password,
        },
      )

      const { accessToken, refreshToken } = auth.data

      axiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${accessToken}`

      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)

      return {
        success: true,
        redirectTo: '/',
      }
    }

    return {
      success: false,
      error: {
        name: 'LoginError',
        message: 'Invalid username or password',
      },
    }
  },
  logout: async () => {
    const refresh_token = localStorage.getItem('refreshToken')

    try {
      await axiosInstance.post<LoginRoDto>(
        `${process.env.API_URL}/auth/logout`,
        {
          refresh_token,
        },
      )
    } catch (error) {
      console.log(error, 'LOGOUT ERROR')
    }

    axiosInstance.defaults.headers.common['Authorization'] = undefined

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    return {
      success: true,
      redirectTo: '/login',
    }
  },
  check: async () => {
    try {
      await axiosInstance.post<boolean>(
        `${process.env.API_URL}/auth/check-tokens`,
      )

      return {
        authenticated: true,
      }
    } catch (error) {
      return {
        authenticated: false,
        redirectTo: '/login',
      }
    }
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const { data: user } = await axiosInstance.get<UserRoDto>(
      `${process.env.API_URL}/users/by-token`,
    )

    return user
  },
  onError: async error => {
    return { error }
  },
}
