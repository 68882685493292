import React from 'react'
import 'dayjs/locale/ru'
import dayjs from 'dayjs'

import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  RefreshButton,
  EmailField,
  DateField,
} from '@refinedev/antd'
import { Typography } from 'antd'
import { SupportRo } from '@/services/data-contracts'

const { Title, Link, Text } = Typography

export const SupportShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<SupportRo>()
  const { isLoading, data } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Обращения"
      headerButtons={() => (
        <>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Имя</Title>
      <TextField value={record?.name ?? ''} />
      <Title level={5}>Почта</Title>
      <EmailField value={record?.email} />
      <Title level={5}>Дата создания</Title>
      <DateField
        format="DD.MM.YYYY HH:mm:ss"
        locales={dayjs().locale('ru').format()}
        value={record?.createdAt}
      />
      <Title level={5}>Телефон</Title>
      <TextField value={record?.phone} />
      <Title level={5}>Тема</Title>
      <TextField value={record?.theme} />
      <Title level={5}>Категория</Title>
      <TextField value={record?.category} />
      <Title level={5}>Описание</Title>
      {record?.description ? (
        <TextField value={record?.description} />
      ) : (
        <Text type="secondary">Без Описания</Text>
      )}
      <Title level={5}>Документ</Title>
      {record?.preview ? (
        <Link href={record?.preview.location} download>
          {record?.preview.originalname}
        </Link>
      ) : (
        <Text type="secondary">Без документа</Text>
      )}
    </Show>
  )
}
// preview
