import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input, InputNumber } from 'antd'
import { OrganizationsCategoryDto } from '@/services/data-contracts'

export const OrganizationsCategoriesEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm<OrganizationsCategoryDto>({
    redirect: 'show',
  })

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Редактирование Категории Организации"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Категории',
            },
          ]}
        >
          <Input placeholder="Например: Выставка | Гастроли | Детский праздник" />
        </Form.Item>
        <Form.Item label="Сортировка" name={['sort']} initialValue={0}>
          <InputNumber min={0} />
        </Form.Item>
      </Form>
    </Create>
  )
}
