import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Typography,
} from 'antd'
import { ArtistsCategoriesDto } from '@/services/data-contracts'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

export const ArtistsCategoriesCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm<ArtistsCategoriesDto>()

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание Категории Артиста"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Категории',
            },
          ]}
        >
          <Input placeholder="Например: Актеры" />
        </Form.Item>
        <Form.List
          name="children"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 2) {
                  return Promise.reject(
                    new Error(
                      'Необходимо создать минимум 2 Дочернии категории',
                    ),
                  )
                }

                if (names?.[0] && names?.[0]?.name === names?.[1]?.name) {
                  return Promise.reject(
                    new Error(
                      'Названия в дочерних категориях должны быть разные',
                    ),
                  )
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              <Typography.Paragraph>
                <Typography.Text type="danger">*</Typography.Text> Необходимо
                создать минимум 2 Дочернии категории
              </Typography.Paragraph>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    style={{ width: 300 }}
                    name={[name, 'name']}
                    rules={[
                      {
                        required: true,
                        whitespace: false,
                        message: 'Необходимо указать название',
                      },
                    ]}
                  >
                    <Input placeholder="Например: Актер | Актриса" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить дочернюю категорию
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item label="Сортировка" name={['sort']} initialValue={0}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Популярность" name={['popular']}>
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>
      </Form>
    </Create>
  )
}
