import {
  ICommand,
  TextAreaTextApi,
  TextState,
  selectWord,
} from '@uiw/react-md-editor'

const underline: ICommand = {
  name: 'underline',
  keyCommand: 'underline',
  buttonProps: { 'aria-label': 'Insert underline' },
  icon: (
    <svg width="12" height="12" viewBox="0 0 1024 1024">
      <path
        fill="currentColor"
        d="M960 896V1024H64v-128zM256 0v448a256 256 0 1 0 512 0V0h128v448c0 212.0704-171.9296 384-384 384s-384-171.9296-384-384V0z"
      />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = selectWord({
      text: state.text,
      selection: state.selection,
      prefix: '',
    })
    const state1 = api.setSelectionRange(newSelectionRange)
    // Replaces the current selection with the strikethrough mark up
    const state2 = api.replaceSelection(`<u>${state1.selectedText}</u>`)
    // Adjust the selection to not contain the ~~
    api.setSelectionRange({
      start: state2.selection.end - 4 - state1.selectedText.length,
      end: state2.selection.end - 4,
    })
  },
}
export default underline
