import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, Upload, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import {
  CompilationsDto,
  CompilationsWithEventsRo,
  RegionRoDto,
} from '@/services/data-contracts'
import { useWatch } from 'antd/es/form/Form'
import { previewAnt } from '@/services/antUpload'
import useSelectProps from '@/hooks/useSelectProps'

const { Title } = Typography

export const CompilationsEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    onFinish,
    formProps,
    saveButtonProps,
    form,
    queryResult: { data: formResult },
  } = useForm<CompilationsWithEventsRo>({
    redirect: 'show',
  })

  const {
    selectProps: selectRegions,
    queryResult: { data },
  } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const poster = useWatch('poster', form)
  const region = useWatch('region', form)

  const dataEvents = formResult?.data.events?.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const {
    onChange: onChangeEvents,
    selectProps: selectEvents,
    options: optionsEvents,
    values: valuesEvents,
  } = useSelectProps({
    data: dataEvents,
    filters: [
      {
        field: 'region',
        operator: 'eq',
        value: data?.data?.find(({ id }) => id === region)?.key || 'msk',
      },
    ],
    resource: 'events/select',
  })

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      events: valuesEvents?.map(({ value }) => value),
      poster: values.poster?.fileList.map((file: any) => file.response.id),
      region: { id: values.region },
    } as CompilationsDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Редактирование Подбороки">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Например: Кино" />
        </Form.Item>
        <Form.Item label="Ключ" name={['key']}>
          <Input placeholder="Например: slider" />
        </Form.Item>
        <Form.Item label="Постер/Картинка" name="poster">
          <Upload
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            fileList={previewAnt(poster)}
            onPreview={file => {
              window.open(file?.url || file?.response?.location)
            }}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Регион"
          name={['region']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...selectRegions}
            showSearch={false}
            placeholder="Выберите регион"
          />
        </Form.Item>
        <Form.Item
          label="Мероприятия"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectEvents}
            onChange={onChangeEvents}
            value={valuesEvents as any}
            options={optionsEvents}
            mode="multiple"
            placeholder="..."
          />
        </Form.Item>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
