import React, { useEffect, useState } from 'react'
import {
  CustomResponse,
  IResourceComponentsProps,
  useCustom,
  useCustomMutation,
} from '@refinedev/core'
import { Form, Input, Typography, Card, Button } from 'antd'
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveFilled,
} from '@ant-design/icons'
import underline from '@/services/md/underline-command'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import {
  PolicyDataDto,
  PolicyDataRo,
  PolicyInfoRo,
} from '@/services/data-contracts'

const { Title } = Typography

export const PolicyEdit: React.FC<IResourceComponentsProps> = () => {
  const { data, isLoading } = useCustom<PolicyDataRo>({
    url: `${process.env.API_URL}/policy`,
    method: 'get',
  })

  const { mutate: savePolicy } = useCustomMutation()

  const [dataForm, setDataForm] = useState<PolicyInfoRo[]>()

  const callbackForm = async (data: PolicyDataDto) => {
    const deleted = dataForm
      .filter(dataItem => !data.blocks.find(block => dataItem.id === block.id))
      .map(deleteItem => deleteItem.id)

    const blocks = data.blocks.map((block, index) => ({
      ...block,
      order: index + 1,
    }))

    await savePolicy(
      {
        values: { deleted, blocks },
        method: 'post',
        url: `${process.env.API_URL}/policy`,
        errorNotification: () => ({
          message: 'Произошла ошибка при сохранении',
          type: 'error',
        }),
      },
      {
        onSuccess: () => {
          window.location.href = '/policy'
        },
      },
    )
  }

  useEffect(() => {
    if (!isLoading && data) {
      setDataForm((data as CustomResponse<PolicyDataRo>).data.blocks)
    }
  }, [data, isLoading])

  return (
    dataForm && (
      <Form
        fields={[{ name: 'blocks', value: dataForm }]}
        onFinish={callbackForm}
        layout="vertical"
      >
        <Title level={5}>Список пунктов</Title>

        <Form.List name="blocks">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <Card
                    style={{ marginBottom: 20 }}
                    title={`${index + 1}.`}
                    key={`${key}-${name}`}
                    extra={
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name)
                        }}
                      />
                    }
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Название" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <MDEditor
                        data-color-mode="light"
                        commands={[underline, ...getCommands()]}
                      />
                    </Form.Item>
                  </Card>
                )
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '100%' }}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Button
          htmlType="submit"
          type="primary"
          style={{ width: '100%' }}
          block
          icon={<SaveFilled />}
        >
          Сохранить
        </Button>
      </Form>
    )
  )
}
