import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input, Upload, Typography, Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { NewsDto } from '@/services/data-contracts'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'

const { Title } = Typography

export const NewsCreate: React.FC<IResourceComponentsProps> = () => {
  const { onFinish, formProps, saveButtonProps } = useForm()

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      preview: values.preview?.fileList.map((file: any) => file.response.id)[0],
    } as NewsDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Создание Новости">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название новости"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Например: Обновление дизайна сайта" />
        </Form.Item>
        <Form.Item label="Ресурс" name={['source']}>
          <Input placeholder="..." />
        </Form.Item>
        <Form.Item
          label="Полное описание"
          name={['description']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>
        <Form.Item label="Превью описание" name={['previewDescription']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>
        <Form.Item label="Превью" name="preview">
          <Upload
            maxCount={1}
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            onPreview={file => window.open(file?.response?.location)}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Изменение в расписании"
          name={['scheduleChange']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
