import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, Typography } from 'antd'
import {
  CompilationsDto,
  EventRo,
  RegionRoDto,
} from '@/services/data-contracts'
import { useWatch } from 'antd/es/form/Form'

const { Paragraph } = Typography

export const CompilationsCreateTop: React.FC<IResourceComponentsProps> = () => {
  const { onFinish, formProps, saveButtonProps, form } = useForm({
    redirect: 'show',
  })

  const {
    selectProps: selectRegions,
    queryResult: { data },
  } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const region = useWatch('region', form)

  const { selectProps: selectEvents } = useSelect<EventRo>({
    resource: 'events/select',
    pagination: { mode: 'off' },
    filters: [
      {
        field: 'region',
        operator: 'eq',
        value: data?.data?.find(({ id }) => id === region)?.key || 'msk',
      },
    ],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      poster: values.poster?.fileList.map((file: any) => file.response.id),
      region: { id: values.region },
    } as CompilationsDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Создание Подбороки">
      <Paragraph style={{ color: 'red' }}>
        Для каждого региона можно создать только одну подборку Топ 10
      </Paragraph>
      <Paragraph style={{ color: 'red' }}>
        Для изменения подборки перейдите в ее редактирование
      </Paragraph>
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item label="Название" name={['name']} initialValue="Топ 10">
          <Input readOnly disabled placeholder={'Например: Кино'} />
        </Form.Item>
        <Form.Item label="Ключ" name={['key']} initialValue="top10">
          <Input readOnly disabled placeholder={'Например: top10'} />
        </Form.Item>
        <Form.Item
          label="Регион"
          name={['region']}
          initialValue={1}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...selectRegions}
            showSearch={false}
            placeholder={'Выберите регион'}
          />
        </Form.Item>
        <Form.Item
          label="Мероприятия"
          name={['events']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={'Мероприятия для связки'}
            maxCount={10}
            mode="multiple"
            {...selectEvents}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
