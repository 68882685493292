import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import {
  Form,
  Input,
  Upload,
  Typography,
  Select,
  Button,
  Card,
  Space,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { previewAnt } from '@/services/antUpload'
import { useWatch } from 'antd/es/form/Form'
import {
  EventRo,
  OrganizationsCategoryRo,
  OrganizationsDto,
  RegionRoDto,
} from '@/services/data-contracts'
import useSelectProps from '@/hooks/useSelectProps'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'

const { Title } = Typography

export const OrganizationsEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    formProps,
    form,
    onFinish,
    saveButtonProps,
    queryResult: { data },
  } = useForm({
    redirect: 'show',
  })

  const preview = useWatch('preview', form)

  const dataRegions = (data?.data?.regions as RegionRoDto[])?.map(
    ({ id, name }) => ({
      label: name,
      value: id,
    }),
  )

  const dataEvents = (data?.data?.events as EventRo[])?.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const dataCategories = (
    data?.data?.categories as OrganizationsCategoryRo[]
  )?.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const {
    onChange: onChangeRegions,
    selectProps: selectRegions,
    options: optionsRegions,
    values: valuesRegions,
  } = useSelectProps({
    data: dataRegions,
    resource: 'regions',
  })

  const {
    onChange: onChangeEvents,
    selectProps: selectEvents,
    options: optionsEvents,
    values: valuesEvents,
  } = useSelectProps({
    data: dataEvents,
    resource: 'events/all',
    filters: [
      {
        field: 'regions',
        operator: 'eq',
        value: valuesRegions?.map(({ value }) => value),
      },
    ],
  })

  const {
    onChange: onChangeCategories,
    selectProps: selectCategories,
    options: optionsCategories,
    values: valuesCategories,
  } = useSelectProps({
    data: dataCategories,
    resource: 'organizations-categories',
  })

  const handleOnFinish = (values: any) => {
    const orgObject = {
      ...values,
      preview: values.preview
        ? values.preview?.id
          ? { id: values.preview?.id }
          : values.preview?.fileList.map((file: any) => ({
              id: file.response.id,
            }))[0]
        : null,
      events: valuesEvents?.map(({ value }) => ({ id: value })),
      regions: valuesRegions?.map(({ value }) => ({ id: value })),
      categories: valuesCategories?.map(({ value }) => ({ id: value })),
    } as Partial<OrganizationsDto>

    onFinish(orgObject)
  }

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Редактирование Организации"
    >
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Организации',
            },
          ]}
        >
          <Input placeholder="Например: Яркие События" />
        </Form.Item>
        <Form.Item
          label="Род деятельности"
          name={['typeActivity']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите род деятельности Организации',
            },
          ]}
        >
          <Input placeholder="Например: Event-агентство полного цикла" />
        </Form.Item>
        <Form.Item label="Описание" name={['description']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>
        <Form.Item label="Превью организации" name="preview">
          <Upload
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            maxCount={1}
            listType="picture-card"
            fileList={previewAnt(preview)}
            onPreview={file => {
              window.open(file?.url || file?.response?.location)
            }}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Категории"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectCategories}
            mode="multiple"
            showSearch={false}
            onChange={onChangeCategories}
            value={valuesCategories as any}
            options={optionsCategories}
            placeholder="..."
          />
        </Form.Item>

        <Form.Item
          label="Регион"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectRegions}
            mode="multiple"
            showSearch={false}
            onChange={onChangeRegions}
            value={valuesRegions as any}
            options={optionsRegions}
            placeholder="..."
          />
        </Form.Item>

        <Form.Item
          label="Мероприятия"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectEvents}
            onChange={onChangeEvents}
            value={valuesEvents as any}
            options={optionsEvents}
            mode="multiple"
            placeholder="..."
          />
        </Form.Item>

        <Form.List
          name="contacts"
          initialValue={['']}
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 1 contacts'))
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 ? 'Контактная информация' : ''}
                  required={true}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          'Пожалуйста, введите контактную информацию или удалите это поле',
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="+7 (999) 999-99-99 | mail@mail.ru | www.site.ru"
                      style={{ width: '60%' }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Добавить Контактную информацию
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Социальные сети</Title>

          <Form.List name="socials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      style={{ width: 100 }}
                      name={[name, 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: 'VK', value: 'vk' },
                          { label: 'Telegram', value: 'tg' },
                          { label: 'OK', value: 'ok' },
                          { label: 'YouTUbe', value: 'youtube' },
                          { label: 'WWW', value: 'web' },
                        ]}
                        placeholder="Тип социальной сети"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: 300 }}
                      name={[name, 'link']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Ссылка" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
