import React, { useCallback, useState } from 'react'
import { IResourceComponentsProps, useCustom } from '@refinedev/core'
import {
  Form,
  Input,
  Upload,
  Typography,
  Select,
  Button,
  Space,
  Card,
} from 'antd'
import { Create, useForm } from '@refinedev/antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { ArtistsCategoriesRo, ArtistsDto } from '@/services/data-contracts'
import { UploadChangeParam } from 'antd/es/upload'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'

const { Title } = Typography

type ArtistCreateObj = {
  id: number
  createdAt: Date
  updatedAt: Date
  name: string
  description: string
  preview?: UploadChangeParam
  categories?: number[][]
  events?: number[]
}

export const ArtistsCreate: React.FC<IResourceComponentsProps> = () => {
  const [selectCategories, setSelectCategories] = useState<number[]>([])

  const { onFinish, formProps, saveButtonProps } = useForm<ArtistsDto>()

  const categories = useCustom<ArtistsCategoriesRo[]>({
    url: `${process.env.API_URL}/artists-categories/all`,
    method: 'get',
  })

  const handleOnFinish = (values: any) => {
    const formValues = values as ArtistCreateObj

    onFinish({
      ...values,
      categories: selectCategories.map(category => ({
        id: category,
      })),
      preview: formValues.preview?.fileList.map((file: any) => ({
        id: file.response.id,
      }))[0],
    } as ArtistsDto)
  }

  const handleChangeCategory = useCallback((value: number[]) => {
    setSelectCategories(value)
  }, [])

  return (
    <Create saveButtonProps={saveButtonProps} title="Создание Артиста">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название(ФИО)"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Артиста',
            },
          ]}
        >
          <Input placeholder="Например: Artik & Asti" />
        </Form.Item>

        <Form.Item label="Описание" name={['description']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>
        <Form.Item label="Превью артиста" name="preview">
          <Upload
            maxCount={1}
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            onPreview={file => window.open(file?.response?.location)}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label="Категории"
        >
          <Select
            onChange={handleChangeCategory}
            showSearch={false}
            placeholder="..."
            mode="multiple"
            options={categories?.data?.data?.map(({ name, children }) => ({
              label: <span>{name}</span>,
              title: 'manager',
              options: children.map(({ id, name }) => ({
                label: <span>{name}</span>,
                value: id,
              })),
            }))}
          />
        </Form.Item>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Социальные сети</Title>

          <Form.List name="socials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      style={{ width: 100 }}
                      name={[name, 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: 'VK', value: 'vk' },
                          { label: 'Telegram', value: 'tg' },
                          { label: 'OK', value: 'ok' },
                          { label: 'YouTUbe', value: 'youtube' },
                          { label: 'WWW', value: 'web' },
                        ]}
                        placeholder="Тип социальной сети"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: 300 }}
                      name={[name, 'link']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Ссылка" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
