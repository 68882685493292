import { useSelect } from '@refinedev/antd'

import { useEffect, useState } from 'react'

type UseSelectProps<T> = {
  data: T[]
  resource: string
  filters?: any
}

const useSelectProps = <T>({ data, resource, filters }: UseSelectProps<T>) => {
  const [values, setValues] = useState([])
  const [changed, setChanged] = useState(false)
  const [request, setRequest] = useState(false)

  const {
    selectProps,
    queryResult: { isSuccess },
  } = useSelect({
    resource,
    pagination: { mode: 'off' },
    filters: filters || [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const options = changed ? selectProps.options : data

  useEffect(() => {
    if (isSuccess && !request) {
      setRequest(true)
    }
  }, [request, isSuccess])

  useEffect(() => {
    if (request && !changed) {
      setChanged(true)
    }
  }, [changed, request])

  useEffect(() => {
    setValues(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length])

  const onChange = (_, options) => {
    setValues(options)
  }

  return { selectProps, values, options, onChange }
}

export default useSelectProps
