import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Typography,
  Upload,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { previewAntUrl } from '@/services/antUpload'
import { useWatch } from 'antd/es/form/Form'
import { EventsUpdateDto } from '@/services/data-contracts'
import { UploadChangeParam } from 'antd/es/upload'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'
import dayjs from 'dayjs'

const { Title, Text } = Typography

type EventsUpdate = {
  name: string
  dates: { date1: Date; date2: Date }[]
  description: string
  poster: string | UploadChangeParam
  horizontal_poster: string | UploadChangeParam
}

export const EventsEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    onFinish,
    formProps,
    saveButtonProps,
    form,
    queryResult: { data },
  } = useForm({
    redirect: 'show',
  })

  const isManual = data?.data?.manual

  const poster = useWatch('poster', form)
  const horizontal_poster = useWatch('horizontal_poster', form)

  const handleOnFinish = (values: any) => {
    const vals = values as EventsUpdate

    const dto = {
      ...vals,
      ...(isManual && {
        dates: vals.dates.map(({ date1, date2 }) => `${date1} - ${date2}`),
      }),
      poster:
        typeof vals.poster === 'string'
          ? vals.poster
          : vals.poster.file.response.location,
      horizontal_poster:
        typeof vals.horizontal_poster === 'string'
          ? vals.horizontal_poster
          : vals.horizontal_poster.file.response.location,
    } as EventsUpdateDto

    onFinish(dto)
  }

  const formPropsFormat = {
    ...formProps,
    initialValues: {
      ...formProps.initialValues,
      dates: formProps.initialValues?.dates?.map(date => ({
        date1: dayjs(date.split(' - ')[0]),
        date2: dayjs(date.split(' - ')[1]),
      })),
    },
  }

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Редактирование Мероприятия"
    >
      <Form {...formPropsFormat} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название Мероприятия"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="..." />
        </Form.Item>
        <Form.Item label="Описание" name={['description']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>

        {isManual && (
          <>
            <Form.Item
              label="Ссылка на Мероприятие"
              name={['url']}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder="..." />
            </Form.Item>
            <Form.Item
              label="Возрастное ограничение"
              name={['age']}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder="Одно из: 0+; 6+; 12+; 16+; 18+;" />
            </Form.Item>

            <Card style={{ marginBottom: 20 }}>
              <Title level={5}>Расписание</Title>

              <Form.List name="dates">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Text>Начало: </Text>
                        <Form.Item
                          {...restField}
                          style={{ width: 200 }}
                          name={[name, 'date1']}
                          rules={[
                            {
                              required: true,
                              message: 'Поле должно быть заполнено',
                            },
                          ]}
                        >
                          <DatePicker showTime />
                        </Form.Item>
                        -<Text>Конец: </Text>
                        <Form.Item
                          {...restField}
                          style={{ width: 200 }}
                          name={[name, 'date2']}
                          rules={[
                            {
                              required: true,
                              message: 'Поле должно быть заполнено',
                            },
                          ]}
                        >
                          <DatePicker showTime />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Добавить
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>

            <Form.Item
              label="Минимальная цена"
              name={['price_min']}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                style={{ width: 200 }}
                formatter={value =>
                  `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                placeholder="..."
              />
            </Form.Item>
            <Form.Item
              label="Максимальная цена"
              name={['price_max']}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber
                style={{ width: 200 }}
                formatter={value =>
                  `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                placeholder="..."
              />
            </Form.Item>
            <Form.Item
              label="Электронные билеты"
              name={['eticket']}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} placeholder="..." />
            </Form.Item>
            <Form.Item
              label="Доступные билеты"
              name={['available_tickets']}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} placeholder="..." />
            </Form.Item>
          </>
        )}

        <Form.Item label="Постер" name="poster">
          <Upload
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            fileList={previewAntUrl(poster)}
            onPreview={file => {
              window.open(file?.url || file?.response?.location)
            }}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item label="Горизонтальный постер" name="horizontal_poster">
          <Upload
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            fileList={previewAntUrl(horizontal_poster)}
            onPreview={file => {
              window.open(file?.url || file?.response?.location)
            }}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item label="Состояние" name={['deletedAt']}>
          <Switch unCheckedChildren="Опубликовано" checkedChildren="Удалено" />
        </Form.Item>
      </Form>
    </Create>
  )
}
