import React from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  HttpError,
  useGetIdentity,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space } from 'antd'
import {
  EventRo,
  RegionRoDto,
  UserRoDto,
  VenuesDataRo,
} from '@/services/data-contracts'

export const CompilationsListTop: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<VenuesDataRo, HttpError>()

  const { data: user } = useGetIdentity<UserRoDto>()
  const isAdmin = user?.roles === 6

  return (
    <List
      canCreate
      title="Список Подборок"
      createButtonProps={{ children: 'Создать' }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Название" />
        <Table.Column dataIndex="key" title="Ключ" />
        <Table.Column
          dataIndex="region"
          title="Регион"
          render={(value: RegionRoDto) => `${value.name}`}
        />
        <Table.Column
          dataIndex="events"
          title="Мероприятия"
          render={(value: EventRo[]) => `${value.length} шт.`}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => {
            const isAccessRegions = record.region.key === user.region.key
            const access = isAdmin || isAccessRegions

            return (
              <Space>
                {access && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
                <ShowButton hideText size="small" recordItemId={record.id} />
                {access && (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                )}
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
