import React, { useEffect } from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  HttpError,
  useSelect,
  LogicalFilter,
  useGetIdentity,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space, Select } from 'antd'
import {
  EventRo,
  RegionRoDto,
  UserRoDto,
  VenuesDataRo,
} from '@/services/data-contracts'

export const CompilationsList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity<UserRoDto>()
  const isAdmin = user?.roles === 6

  const { tableProps, filters, setFilters } = useTable<
    VenuesDataRo,
    HttpError
  >()

  useEffect(() => {
    if (!isAdmin && user?.region.key) {
      setFilters([
        {
          field: 'region',
          operator: 'eq',
          value: user.region.key,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, user?.region.key])

  const { ...selectProps } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'key',
  })

  const regionFilter = (filters as LogicalFilter[]).find(
    filter => filter.field === 'region',
  )?.value

  const currentSelect = selectProps.options?.find(
    ({ value }) => value === (regionFilter || 'msk'),
  )

  return (
    <List
      canCreate
      title="Список Подборок"
      createButtonProps={{ children: 'Создать' }}
    >
      {isAdmin && (
        <Select
          placeholder="Регион"
          style={{ width: 300, marginBottom: 20 }}
          value={currentSelect}
          onChange={value => {
            setFilters(
              [
                {
                  field: 'region',
                  operator: 'eq',
                  value,
                },
              ],
              'replace',
            )
          }}
          {...selectProps}
        />
      )}
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title={'Название'} />
        <Table.Column dataIndex="key" title={'Ключ'} />
        <Table.Column
          dataIndex="region"
          title={'Регион'}
          render={(value: RegionRoDto) => `${value.name}`}
        />
        <Table.Column
          dataIndex="events"
          title={'Мероприятия'}
          render={(value: EventRo[]) => `${value.length} шт.`}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
