import React, { useCallback, useEffect, useState } from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  HttpError,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space, Flex, Input } from 'antd'
import { NewsAdminDataRo } from '@/services/data-contracts'
import { format } from 'date-fns'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from '@/scripts/debounce'

export const NewsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, setFilters } = useTable<NewsAdminDataRo, HttpError>()

  const [inputText, setInputText] = useState<string>()
  const debounced = useDebounce(inputText)

  useEffect(() => {
    if (debounced !== undefined) {
      setFilters(
        [
          {
            field: 'name',
            operator: 'eq',
            value: debounced?.trim(),
          },
        ],
        'merge',
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  const searchHandler = useCallback(props => {
    setInputText(props.target.value)
  }, [])

  return (
    <List
      canCreate
      title="Список Новостей"
      createButtonProps={{ children: 'Создать' }}
    >
      <Flex gap="middle" style={{ marginBottom: 20 }}>
        <Input
          value={inputText}
          addonAfter={<SearchOutlined />}
          onChange={searchHandler}
          placeholder="Название Места проведения"
        />
      </Flex>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Название новости" />
        <Table.Column dataIndex="source" title="Ресурс" />
        <Table.Column
          dataIndex="createdAt"
          title="Дата создания/синхронизации"
          render={value => format(new Date(value), 'dd.MM.yyyy HH:mm:ss')}
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
