import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

export const VariablesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    redirect: 'show',
  })

  return (
    <Create saveButtonProps={saveButtonProps} title="Редактирование Информации">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Переменной',
            },
          ]}
        >
          <Input placeholder="Например: email" />
        </Form.Item>
        <Form.Item
          label="Значение"
          name={['value']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите значение Переменной',
            },
          ]}
        >
          <Input placeholder="Например: info@integration.media" />
        </Form.Item>
      </Form>
    </Create>
  )
}
