import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { Typography } from 'antd'
import { OrganizationsCategoryRo } from '@/services/data-contracts'

const { Title } = Typography

export const OrganizationsCategoriesShow: React.FC<
  IResourceComponentsProps
> = () => {
  const { queryResult } = useShow<OrganizationsCategoryRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Категории Организации"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      {record && (
        <>
          <Title level={5}>ID</Title>
          <NumberField value={record?.id ?? ''} />
          <Title level={5}>Название</Title>
          <TextField value={record?.name ?? ''} />
          <Title level={5}>Сортировка</Title>
          <NumberField value={record?.sort ?? 0} />
        </>
      )}
    </Show>
  )
}
