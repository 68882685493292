import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { Card, Typography } from 'antd'
import { VariablesRo } from '@/services/data-contracts'

const { Title } = Typography

export const VariablesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<VariablesRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Информации"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      {record && (
        <>
          <Card style={{ marginBottom: 25 }}>
            <Title level={5}>ID</Title>
            <NumberField value={record?.id ?? ''} />
            <Title level={5}>Название</Title>
            <TextField value={record?.name ?? ''} />
            <Title level={5}>Значение</Title>
            <TextField value={record?.value ?? ''} />
          </Card>
        </>
      )}
    </Show>
  )
}
