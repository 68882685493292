import React, { useCallback, useEffect, useState } from 'react'
import { IResourceComponentsProps, useCustom } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import {
  Form,
  Input,
  Upload,
  Typography,
  Select,
  Space,
  Card,
  Button,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useWatch } from 'antd/es/form/Form'
import { ArtistsDto, ArtistsCategoriesRo } from '@/services/data-contracts'

import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'
import { previewAnt } from '@/services/antUpload'
import useSelectProps from '@/hooks/useSelectProps'

const { Title } = Typography

export const ArtistsEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectCategories, setSelectCategories] = useState<number[]>([])

  const {
    formProps,
    form,
    onFinish,
    saveButtonProps,
    queryResult: { data },
  } = useForm({
    redirect: 'show',
  })

  const preview = useWatch('preview', form)

  const dataCategories = (data?.data?.categories as ArtistsCategoriesRo[])?.map(
    ({ id }) => id,
  )

  const categories = useCustom<ArtistsCategoriesRo[]>({
    url: `${process.env.API_URL}/artists-categories/all`,
    method: 'get',
  })

  const dataEvents = data?.data.events?.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const {
    onChange: onChangeEvents,
    selectProps: selectEvents,
    options: optionsEvents,
    values: valuesEvents,
  } = useSelectProps({
    data: dataEvents,
    filters: [],
    resource: 'events/select',
  })

  const handleOnFinish = (values: any) => {
    const orgObject = {
      ...values,
      preview: values.preview
        ? values.preview?.id
          ? { id: values.preview?.id }
          : values.preview?.fileList.map((file: any) => ({
              id: file.response.id,
            }))[0]
        : null,

      events: valuesEvents.map(event => ({ id: event.value })),
      categories: selectCategories?.map(category => ({ id: category })),
    } as Partial<ArtistsDto>

    onFinish(orgObject)
  }

  const handleChangeCategory = useCallback((value: number[]) => {
    setSelectCategories(value)
  }, [])

  useEffect(() => {
    dataCategories?.length && setSelectCategories(dataCategories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCategories?.length])

  return (
    <Create saveButtonProps={saveButtonProps} title="Редактирование Артиста">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название(ФИО)"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название Артиста',
            },
          ]}
        >
          <Input placeholder="Например: Artik & Asti" />
        </Form.Item>

        <Form.Item label="Описание" name={['description']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>
        <Form.Item label="Превью артиста" name="preview">
          <Upload
            maxCount={1}
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            fileList={previewAnt(preview)}
            onPreview={file => {
              window.open(file?.url || file?.response?.location)
            }}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label="Категории"
        >
          <Select
            onChange={handleChangeCategory}
            value={selectCategories}
            showSearch={false}
            placeholder="..."
            mode="multiple"
            options={categories?.data?.data?.map(({ name, children }) => ({
              label: <span>{name}</span>,
              title: 'manager',
              options: children.map(({ id, name }) => ({
                label: <span>{name}</span>,
                value: id,
              })),
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Мероприятия"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectEvents}
            onChange={onChangeEvents}
            value={valuesEvents as any}
            options={optionsEvents}
            mode="multiple"
            placeholder="..."
          />
        </Form.Item>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Социальные сети</Title>

          <Form.List name="socials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      style={{ width: 100 }}
                      name={[name, 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: 'VK', value: 'vk' },
                          { label: 'Telegram', value: 'tg' },
                          { label: 'OK', value: 'ok' },
                          { label: 'YouTUbe', value: 'youtube' },
                          { label: 'WWW', value: 'web' },
                        ]}
                        placeholder="Тип социальной сети"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: 300 }}
                      name={[name, 'link']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Ссылка" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
