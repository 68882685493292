import React, { memo, useCallback, useMemo } from 'react'
import { DingdingOutlined } from '@ant-design/icons'
import { Layout, Menu, theme } from 'antd'
import { LogoutOutlined, UnorderedListOutlined } from '@ant-design/icons'
import {
  useLogout,
  useTitle,
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useRouterContext,
  useMenu,
  useLink,
  useRouterType,
  useActiveAuthProvider,
  pickNotDeprecated,
  useWarnAboutChange,
  useGetIdentity,
} from '@refinedev/core'

import { RefineThemedLayoutV2SiderProps, ThemedTitleV2 } from '@refinedev/antd'
import { UserRoDto } from '@/services/data-contracts'

export const Sider: React.FC<RefineThemedLayoutV2SiderProps> = memo(
  ({
    Title: TitleFromProps,
    render,
    meta,
    fixed,
    activeItemDisabled = false,
  }) => {
    const { token } = theme.useToken()

    const isExistAuthentication = useIsExistAuthentication()
    const routerType = useRouterType()
    const NewLink = useLink()
    const { warnWhen, setWarnWhen } = useWarnAboutChange()
    const { Link: LegacyLink } = useRouterContext()
    const Link = routerType === 'legacy' ? LegacyLink : NewLink
    const TitleFromContext = useTitle()
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta })
    const authProvider = useActiveAuthProvider()
    const { mutate: mutateLogout } = useLogout({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    })

    const { data: user } = useGetIdentity<UserRoDto>()
    const isAdmin = user?.roles === 6

    const RenderToTitle = TitleFromProps ?? TitleFromContext ?? ThemedTitleV2

    const renderTreeView = useCallback(
      (tree: ITreeMenu[], selectedKey?: string) => {
        return tree.map((item: ITreeMenu) => {
          const {
            icon,
            label,
            route,
            key,
            name,
            children,
            parentName,
            meta,
            options,
          } = item

          if (children.length > 0) {
            return (
              <CanAccess
                key={item.key}
                resource={name.toLowerCase()}
                action="list"
                params={{
                  resource: item,
                }}
              >
                <Menu.SubMenu
                  key={item.key}
                  icon={icon ?? <UnorderedListOutlined />}
                  title={label}
                >
                  {renderTreeView(children, selectedKey)}
                </Menu.SubMenu>
              </CanAccess>
            )
          }
          const isSelected = key === selectedKey
          const isRoute = !(
            pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
              undefined && children.length === 0
          )

          const linkStyle: React.CSSProperties =
            activeItemDisabled && isSelected ? { pointerEvents: 'none' } : {}

          return (
            <CanAccess
              key={item.key}
              resource={name.toLowerCase()}
              action="list"
              params={{
                resource: item,
              }}
            >
              <Menu.Item
                key={item.key}
                icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                style={linkStyle}
              >
                <Link to={route ?? ''} style={linkStyle}>
                  {label}
                </Link>
                {/* {!siderCollapsed && isSelected && (
                  <div className="ant-menu-tree-arrow" />
                )} */}
              </Menu.Item>
            </CanAccess>
          )
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    const handleLogout = useCallback(() => {
      if (warnWhen) {
        const confirm = window.confirm(
          'Вы уверены, что хотите уйти? У вас есть несохраненные изменения.',
        )

        if (confirm) {
          setWarnWhen(false)
          mutateLogout()
        }
      } else {
        mutateLogout()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logout = isExistAuthentication && (
      <Menu.Item
        key="logout"
        onClick={() => handleLogout()}
        icon={<LogoutOutlined />}
      >
        Выйти из ЛК
      </Menu.Item>
    )

    const items = useMemo(
      () =>
        renderTreeView(
          menuItems.filter(item => {
            if (isAdmin) return true

            return !['users', 'regions', 'categories'].includes(item.name)
          }),
          selectedKey,
        ),
      [isAdmin, menuItems, renderTreeView, selectedKey],
    )

    const renderSider = () => {
      if (render) {
        return render({
          dashboard: undefined,
          items,
          logout,
          collapsed: false,
        })
      }
      return (
        <>
          {items}
          {logout}
        </>
      )
    }

    const renderMenu = () => {
      return (
        <Menu
          selectedKeys={selectedKey ? [selectedKey] : []}
          defaultOpenKeys={defaultOpenKeys}
          mode="inline"
          style={{
            paddingTop: '8px',
            border: 'none',
            overflow: 'auto',
            height: 'calc(100% - 72px)',
          }}
        >
          {renderSider()}
        </Menu>
      )
    }

    const siderStyles: React.CSSProperties = {
      backgroundColor: token.colorBgContainer,
      borderRight: `1px solid ${token.colorBgElevated}`,
    }

    if (fixed) {
      siderStyles.position = 'fixed'
      siderStyles.top = 0
      siderStyles.height = '100vh'
      siderStyles.zIndex = 999
    }

    return (
      <>
        {fixed && (
          <div
            style={{
              width: '300px',
              transition: 'all 0.2s',
            }}
          />
        )}
        <Layout.Sider
          style={siderStyles}
          collapsedWidth={80}
          breakpoint="lg"
          width={300}
        >
          <div
            style={{
              width: '300px',
              padding: '0 16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '64px',
              backgroundColor: token.colorBgElevated,
              fontSize: '14px',
            }}
          >
            <RenderToTitle
              text="Свободное время"
              icon={<DingdingOutlined />}
              collapsed={false}
            />
          </div>
          {renderMenu()}
        </Layout.Sider>
      </>
    )
  },
)
