import React, { useCallback, useEffect, useState } from 'react'
// import { toDate } from 'date-fns-tz'
import {
  IResourceComponentsProps,
  BaseRecord,
  HttpError,
  LogicalFilter,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space, Flex, Input } from 'antd'
import { VariablesAdminDataRo } from '@/services/data-contracts'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from '@/scripts/debounce'

export const VariablesList: React.FC<IResourceComponentsProps> = () => {
  const [inputText, setInputText] = useState<string>()
  const debounced = useDebounce(inputText)

  const { tableProps, setFilters, filters } = useTable<
    VariablesAdminDataRo,
    HttpError
  >()

  useEffect(() => {
    if (debounced !== undefined) {
      setFilters(
        [
          {
            field: 'name',
            operator: 'eq',
            value: debounced?.trim(),
          },
        ],
        'merge',
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  useEffect(() => {
    const input = (filters as LogicalFilter[]).find(
      filter => filter.field === 'name',
    )?.value

    setInputText(input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchHandler = useCallback(props => {
    setInputText(props.target.value)
  }, [])

  return (
    <List
      canCreate
      title="Список Информации"
      createButtonProps={{ children: 'Создать' }}
    >
      <Flex gap="middle" style={{ marginBottom: 20 }}>
        <Input
          value={inputText}
          addonAfter={<SearchOutlined />}
          onChange={searchHandler}
          placeholder="Название Артиста"
        />
      </Flex>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Название" />
        <Table.Column dataIndex="value" title="Значение" />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
