import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  EmailField,
  TextField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { Typography } from 'antd'
import { Roles } from './enums'

const { Title } = Typography

export const UsersShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Пользователя"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Почта</Title>
      <EmailField value={record?.email} />
      <Title level={5}>Роль</Title>
      <TextField
        value={record?.roles ? Roles[record?.roles as keyof typeof Roles] : ''}
      />
      <Title level={5}>Регион</Title>
      <TextField value={record?.region?.name || ''} />
    </Show>
  )
}
