import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { Card, Image, List, Typography } from 'antd'
import { ArtistsRo } from '@/services/data-contracts'
import MDEditor from '@uiw/react-md-editor'

const { Title } = Typography

export const ArtistsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ArtistsRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Артиста"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      {record && (
        <>
          <Card style={{ marginBottom: 25 }}>
            <Title level={5}>ID</Title>
            <NumberField value={record?.id ?? ''} />
            <Title level={5}>Название</Title>
            <TextField value={record?.name ?? ''} />

            <Title level={5}>Превью</Title>
            <Image width={300} src={record?.preview?.location ?? ''} />
          </Card>
          <Card style={{ marginBottom: 25 }}>
            <Title level={3}>Описание</Title>
            <MDEditor.Markdown
              source={record?.description}
              style={{
                whiteSpace: 'pre-wrap',
                background: 'transparent',
                color: 'black',
                marginBottom: 20,
              }}
            />
            <Title level={5}>Категории</Title>
            <List
              size="small"
              bordered
              dataSource={record?.categories}
              style={{ marginBottom: 25 }}
              renderItem={item => (
                <List.Item>
                  <a href={`/artists-categories/show/${item.id}`}>
                    {item.name}
                  </a>
                </List.Item>
              )}
            />
            <Title level={5}>Мероприятия</Title>
            <List
              size="small"
              bordered
              dataSource={record?.events}
              style={{ marginBottom: 25 }}
              renderItem={item => (
                <List.Item>
                  <a href={`/events/show/${item.id}`}>{item.name}</a>
                </List.Item>
              )}
            />
            <Title level={5}>Социальные сети</Title>
            <List
              size="small"
              bordered
              dataSource={record?.socials}
              renderItem={social => (
                <List.Item>{`${social.type} - ${social.link}`}</List.Item>
              )}
            />
          </Card>
          <Card>
            <Title level={3}>Мета</Title>

            <Title level={5}>Заголовок</Title>
            <TextField value={record?.metaTitle ?? ''} />

            <Title level={5}>Описание</Title>
            <TextField value={record?.metaDescription ?? ''} />

            <Title level={5}>Тэги</Title>
            <TextField value={record?.metaKeywords ?? ''} />
          </Card>
        </>
      )}
    </Show>
  )
}
