import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import { Show, NumberField, TextField } from '@refinedev/antd'
import { Card, Table, Typography } from 'antd'
import { IntersectionCategoryKassirRoCategoryAdminRo } from '@/services/data-contracts'

const { Title } = Typography

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'KassirId',
    dataIndex: 'kassirId',
    key: 'kassirId',
  },
  {
    title: 'ParentKassirId',
    dataIndex: 'parentKassirId',
    key: 'parentKassirId',
  },
]

export const CategoriesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IntersectionCategoryKassirRoCategoryAdminRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show isLoading={isLoading} title="Просмотр Категории Кассира">
      <Card style={{ marginBottom: 25 }}>
        <Title level={5}>ID</Title>
        <NumberField value={record?.id ?? ''} />
        <Title level={5}>Навзвание</Title>
        <TextField value={record?.name ?? ''} />
      </Card>

      <Card style={{ marginBottom: 25 }}>
        <Title level={5}>ID Кассира</Title>
        <NumberField value={record?.kassirId ?? ''} />
        <Title level={5}>Родительский ID Кассира</Title>
        <TextField value={record?.parentKassirId ?? 'Нету родителя'} />
      </Card>

      {record?.childrens?.length ? (
        <Card style={{ marginBottom: 25 }}>
          <Title level={3}>Список дочерних категорий</Title>

          <Table dataSource={record?.childrens} columns={columns} />
        </Card>
      ) : null}
    </Show>
  )
}
