import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input, Switch } from 'antd'

export const RegionsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    redirect: 'list',
  })

  return (
    <Create saveButtonProps={saveButtonProps} title="Редактирование Региона">
      <Form {...formProps} layout="vertical">
        <Form.Item label="Название" name={['name']}>
          <Input disabled readOnly />
        </Form.Item>
        <Form.Item label="Ключ" name={['key']}>
          <Input disabled readOnly />
        </Form.Item>
        <Form.Item label="Популярность" name={['popular']}>
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>
      </Form>
    </Create>
  )
}
