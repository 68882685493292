import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, Upload, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import {
  CompilationsDto,
  EventRo,
  RegionRoDto,
} from '@/services/data-contracts'
import { useWatch } from 'antd/es/form/Form'

const { Title } = Typography

export const CompilationsCreate: React.FC<IResourceComponentsProps> = () => {
  const { onFinish, formProps, saveButtonProps, form } = useForm()

  const {
    selectProps: selectRegions,
    queryResult: { data },
  } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const region = useWatch('region', form)

  const { selectProps: selectEvents } = useSelect<EventRo>({
    resource: 'events/select',
    pagination: { mode: 'off' },
    filters: [
      {
        field: 'region',
        operator: 'eq',
        value: data?.data?.find(({ id }) => id === region)?.key || 'msk',
      },
    ],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      poster: values.poster?.fileList.map((file: any) => file.response.id),
      region: { id: values.region },
    } as CompilationsDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Создание Подбороки">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Например: Кино" />
        </Form.Item>
        <Form.Item label="Ключ" name={['key']}>
          <Input placeholder="Например: slider" />
        </Form.Item>
        <Form.Item label="Постер/Картинка" name="poster">
          <Upload
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            onPreview={file => window.open(file?.response?.location)}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Регион"
          name={['region']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...selectRegions}
            showSearch={false}
            placeholder="Выберите регион"
          />
        </Form.Item>
        <Form.Item
          label="Мероприятия"
          name={['events']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Мероприятия для связки"
            mode="multiple"
            {...selectEvents}
          />
        </Form.Item>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
