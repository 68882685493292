import { Authenticated, Refine } from '@refinedev/core'

import {
  ErrorComponent,
  ThemedLayoutV2,
  useNotificationProvider,
} from '@refinedev/antd'

import '@refinedev/antd/dist/reset.css'
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6'
import { App as AntdApp } from 'antd'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { authProvider } from './authProvider'
import { Header } from './components/header'
import { ColorModeContextProvider } from './contexts/color-mode'
import { UsersEdit, UsersList, UsersShow } from './pages/users'
import { Login } from './pages/login'
import {
  VenuesCreate,
  VenuesEdit,
  VenuesList,
  VenuesShow,
} from './pages/venues'
import { dataProviderCRUD } from './services/data-provider'
import { Sider } from './components/sider'
import {
  CompilationsCreate,
  CompilationsEdit,
  CompilationsList,
  CompilationsShow,
} from './pages/compilations'
import { NewsCreate, NewsEdit, NewsList, NewsShow } from './pages/news'
import { RegionsEdit, RegionsList } from './pages/region'
import { CategoriesList, CategoriesShow } from './pages/categories'
import { axiosInstance } from './axios'
import {
  CompilationsCreateTop,
  CompilationsEditTop,
  CompilationsListTop,
  CompilationsShowTop,
} from './pages/compilationsTop'
import { EventsEdit, EventsList, EventsShow } from './pages/events'
import {
  OrganizationsCreate,
  OrganizationsEdit,
  OrganizationsList,
  OrganizationsShow,
} from './pages/organizations'
import {
  OrganizationsCategoriesCreate,
  OrganizationsCategoriesEdit,
  OrganizationsCategoriesList,
  OrganizationsCategoriesShow,
} from './pages/organizations/categories'
import {
  ArtistsCategoriesCreate,
  ArtistsCategoriesEdit,
  ArtistsCategoriesList,
  ArtistsCategoriesShow,
} from './pages/artists/categories'
import {
  ArtistsCreate,
  ArtistsEdit,
  ArtistsList,
  ArtistsShow,
} from './pages/artists'
import { PolicyEdit, PolicyShow } from './pages/policy'
import { SupportList, SupportShow } from './pages/support'
import {
  VariablesCreate,
  VariablesEdit,
  VariablesList,
  VariablesShow,
} from './pages/variables'
import { EventsCreate } from './pages/events/create'

function App() {
  const API_URL = process.env.API_URL as string
  const dataProvider = dataProviderCRUD(API_URL, axiosInstance)

  return (
    <BrowserRouter>
      <ColorModeContextProvider>
        <AntdApp>
          <Refine
            dataProvider={dataProvider}
            notificationProvider={useNotificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            resources={[
              {
                name: 'users',
                list: '/users',
                create: '/users/create',
                edit: '/users/edit/:id',
                show: '/users/show/:id',
                meta: {
                  canDelete: true,
                  label: 'Пользователи',
                },
              },
              {
                name: 'regions',
                list: '/regions',
                edit: '/regions/edit/:id',
                meta: {
                  label: 'Регионы',
                },
              },
              {
                name: 'categories',
                list: '/categories',
                show: '/categories/show/:id',
                meta: {
                  label: 'Категории Кассира',
                },
              },
              {
                name: 'venues',
                list: '/venues',
                show: '/venues/show/:id',
                create: '/venues/create',
                edit: '/venues/edit/:id',
                meta: {
                  canDelete: true,
                  label: 'Площадки',
                },
              },
              {
                name: 'compilations-list',
                meta: {
                  label: 'Подборки',
                },
              },
              {
                name: 'compilations',
                list: '/compilations',
                create: '/compilations/create',
                edit: '/compilations/edit/:id',
                show: '/compilations/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'compilations-list',
                  label: 'Список',
                },
              },
              {
                name: 'compilations/top10',
                list: '/compilations/top10',
                create: '/compilations/top10/create',
                edit: '/compilations/top10/edit/:id',
                show: '/compilations/top10/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'compilations-list',
                  label: 'Подборки ТОП10',
                },
              },
              {
                name: 'news',
                list: '/news',
                create: '/news/create',
                edit: '/news/edit/:id',
                show: '/news/show/:id',
                meta: {
                  canDelete: true,
                  label: 'Новости',
                },
              },
              {
                name: 'events',
                list: '/events',
                edit: '/events/edit/:id',
                show: '/events/show/:id',
                create: '/events/create',
                meta: {
                  canDelete: true,
                  label: 'Мероприятия',
                },
              },
              {
                name: 'organizations-menu',
                meta: {
                  label: 'Организации',
                },
              },
              {
                name: 'organizations',
                list: '/organizations',
                create: '/organizations/create',
                edit: '/organizations/edit/:id',
                show: '/organizations/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'organizations-menu',
                  label: 'Список',
                },
              },
              {
                name: 'organizations-categories',
                list: '/organizations-categories',
                create: '/organizations-categories/create',
                edit: '/organizations-categories/edit/:id',
                show: '/organizations-categories/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'organizations-menu',
                  label: 'Категории',
                },
              },
              {
                name: 'artists-menu',
                meta: {
                  label: 'Артисты',
                },
              },
              {
                name: 'artists',
                list: '/artists',
                create: '/artists/create',
                edit: '/artists/edit/:id',
                show: '/artists/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'artists-menu',
                  label: 'Список',
                },
              },
              {
                name: 'artists-categories',
                list: '/artists-categories',
                create: '/artists-categories/create',
                edit: '/artists-categories/edit/:id',
                show: '/artists-categories/show/:id',
                meta: {
                  canDelete: true,
                  parent: 'artists-menu',
                  label: 'Категории',
                },
              },
              {
                name: 'policy',
                list: '/policy',
                show: '/users/show/:id',
                meta: {
                  label: 'Политика конфинденциальности',
                },
              },
              {
                name: 'support',
                list: '/support',
                show: '/support/show/:id',
                meta: {
                  label: 'Обратная связь',
                },
              },
              {
                name: 'variables',
                list: '/variables',
                create: '/variables/create',
                edit: '/variables/edit/:id',
                show: '/variables/show/:id',
                meta: {
                  label: 'Информация сайта',
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              useNewQueryKeys: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    key="authenticated-inner"
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <ThemedLayoutV2
                      Header={() => <Header sticky />}
                      Sider={props => <Sider {...props} fixed />}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<NavigateToResource resource="blog_posts" />}
                />
                <Route path="/users">
                  <Route index element={<UsersList />} />
                  <Route path="edit/:id" element={<UsersEdit />} />
                  <Route path="show/:id" element={<UsersShow />} />
                </Route>
                <Route path="/policy">
                  <Route index element={<PolicyShow />} />
                  <Route path="edit" element={<PolicyEdit />} />
                </Route>
                <Route path="/venues">
                  <Route index element={<VenuesList />} />
                  <Route path="show/:id" element={<VenuesShow />} />
                  <Route path="edit/:id" element={<VenuesEdit />} />
                  <Route path="create" element={<VenuesCreate />} />
                </Route>
                <Route path="/support">
                  <Route index element={<SupportList />} />
                  <Route path="show/:id" element={<SupportShow />} />
                </Route>
                <Route path="/compilations">
                  <Route index element={<CompilationsList />} />
                  <Route path="edit/:id" element={<CompilationsEdit />} />
                  <Route path="show/:id" element={<CompilationsShow />} />
                  <Route path="create" element={<CompilationsCreate />} />
                </Route>
                <Route path="/compilations/top10">
                  <Route index element={<CompilationsListTop />} />
                  <Route path="edit/:id" element={<CompilationsEditTop />} />
                  <Route path="show/:id" element={<CompilationsShowTop />} />
                  <Route path="create" element={<CompilationsCreateTop />} />
                </Route>
                <Route path="/news">
                  <Route index element={<NewsList />} />
                  <Route path="edit/:id" element={<NewsEdit />} />
                  <Route path="show/:id" element={<NewsShow />} />
                  <Route path="create" element={<NewsCreate />} />
                </Route>
                <Route path="/regions">
                  <Route index element={<RegionsList />} />
                  <Route path="edit/:id" element={<RegionsEdit />} />
                </Route>
                <Route path="/categories">
                  <Route index element={<CategoriesList />} />
                  <Route path="show/:id" element={<CategoriesShow />} />
                </Route>
                <Route path="/events">
                  <Route index element={<EventsList />} />
                  <Route path="show/:id" element={<EventsShow />} />
                  <Route path="edit/:id" element={<EventsEdit />} />
                  <Route path="create" element={<EventsCreate />} />
                </Route>
                <Route path="/organizations">
                  <Route index element={<OrganizationsList />} />
                  <Route path="edit/:id" element={<OrganizationsEdit />} />
                  <Route path="show/:id" element={<OrganizationsShow />} />
                  <Route path="create" element={<OrganizationsCreate />} />
                </Route>
                <Route path="/organizations-categories">
                  <Route index element={<OrganizationsCategoriesList />} />
                  <Route
                    path="edit/:id"
                    element={<OrganizationsCategoriesEdit />}
                  />
                  <Route
                    path="show/:id"
                    element={<OrganizationsCategoriesShow />}
                  />
                  <Route
                    path="create"
                    element={<OrganizationsCategoriesCreate />}
                  />
                </Route>
                <Route path="/artists-categories">
                  <Route index element={<ArtistsCategoriesList />} />
                  <Route path="edit/:id" element={<ArtistsCategoriesEdit />} />
                  <Route path="show/:id" element={<ArtistsCategoriesShow />} />
                  <Route path="create" element={<ArtistsCategoriesCreate />} />
                </Route>
                <Route path="/artists">
                  <Route index element={<ArtistsList />} />
                  <Route path="edit/:id" element={<ArtistsEdit />} />
                  <Route path="show/:id" element={<ArtistsShow />} />
                  <Route path="create" element={<ArtistsCreate />} />
                </Route>
                <Route path="/variables">
                  <Route index element={<VariablesList />} />
                  <Route path="edit/:id" element={<VariablesEdit />} />
                  <Route path="show/:id" element={<VariablesShow />} />
                  <Route path="create" element={<VariablesCreate />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated
                    key="authenticated-outer"
                    fallback={<Outlet />}
                  >
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>
            </Routes>

            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </AntdApp>
      </ColorModeContextProvider>
    </BrowserRouter>
  )
}

export default App
