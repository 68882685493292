import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  TextField,
  NumberField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import {
  Card,
  Col,
  ConfigProvider,
  Image,
  List,
  Row,
  Tag,
  Typography,
} from 'antd'
import MDEditor from '@uiw/react-md-editor'

import dayjs from 'dayjs'
import locale from 'antd/locale/ru_RU'

import 'dayjs/locale/ru'
import { format } from 'date-fns'

dayjs.locale('ru')

const { Title, Link } = Typography

export const EventsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <ConfigProvider locale={locale}>
      <Show
        isLoading={isLoading}
        title="Просмотр Мероприятия"
        headerButtons={() => (
          <>
            <EditButton type="primary">Редактировать</EditButton>
            <DeleteButton>Удалить</DeleteButton>
            <RefreshButton>Перезагрузить</RefreshButton>
          </>
        )}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Title level={5}>ID</Title>
            <TextField value={record?.id ?? ''} />
            <Title style={{ marginTop: 20 }} level={5}>
              Состояние
            </Title>
            <Tag color={record?.deletedAt ? 'magenta' : 'green'}>
              {record?.deletedAt ? 'Удалено' : 'Опубликовано'}
            </Tag>
            <Title style={{ marginTop: 20 }} level={5}>
              Регион
            </Title>
            <TextField value={record?.region.name ?? ''} />
            <Title level={5}>Название</Title>
            <TextField value={record?.name ?? ''} />
            <Title level={5}>Категория</Title>
            <TextField value={record?.category?.name ?? ''} />
            <Card
              style={{
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <Title level={5}>Описание</Title>
              <MDEditor.Markdown
                source={record?.description}
                style={{
                  whiteSpace: 'pre-wrap',
                  background: 'transparent',
                  color: 'black',
                }}
              />
            </Card>
            <Card
              style={{
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <Title level={5}>Расписание</Title>
              <List
                size="small"
                bordered
                dataSource={record?.dates}
                style={{ marginBottom: 25 }}
                renderItem={(item: string) => {
                  const [date1, date2] = item.split(' - ')

                  return (
                    <List.Item>
                      {format(new Date(date1), 'dd.MM.yyyy HH:mm:ss')} -{' '}
                      {format(new Date(date2), 'dd.MM.yyyy HH:mm:ss')}
                    </List.Item>
                  )
                }}
              />
            </Card>
            <Title level={5}>Доступные билеты</Title>
            <NumberField value={record?.available_tickets ?? ''} /> шт.
            <Title level={5}>Цена</Title>
            <TextField value="От" />{' '}
            <NumberField value={record?.price_min ?? ''} />{' '}
            <TextField value="До" />{' '}
            <NumberField value={record?.price_max ?? ''} />
            <Title level={5}>Ссылка</Title>
            <Link target="_blank" href={record?.url}>
              {record?.url ?? ''}
            </Link>
            <Title level={5}>Горизонтальный постер</Title>
            {record?.horizontal_poster ? (
              <Image width={500} src={record?.horizontal_poster} />
            ) : (
              '-'
            )}
            <Title level={5}>Постер</Title>
            <Image width={500} src={record?.poster ?? ''} />
          </Col>
          <Col span={8}>
            <Card title="Место проведения">
              <Title level={5}>Название</Title>
              <TextField value={record?.venue?.name ?? ''} />
              <Title level={5}>Адрес</Title>
              <TextField value={record?.venue?.address ?? ''} />
              <Title level={5}>Постер</Title>
              {record?.venue?.poster ? (
                <Image src={record?.venue?.poster} />
              ) : (
                '-'
              )}
              <Title style={{ marginTop: 20 }} level={5}>
                Ссылка
              </Title>
              <Link target="_blank" href={record?.venue?.url}>
                {record?.venue?.url ?? ''}
              </Link>

              <Card style={{ marginTop: 20 }} title="Зал">
                <Title level={5}>Название</Title>
                <TextField value={record?.hall?.name ?? ''} />
                <Title level={5}>Адрес</Title>
                <TextField value={record?.hall?.address ?? ''} />
              </Card>
            </Card>
          </Col>
        </Row>
      </Show>
    </ConfigProvider>
  )
}
