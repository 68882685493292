import React from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  HttpError,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space } from 'antd'
import { ArtistsCategoriesAdminDataRo } from '@/services/data-contracts'

export const ArtistsCategoriesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ArtistsCategoriesAdminDataRo, HttpError>()

  return (
    <List
      canCreate
      title="Список Категорий Артистов"
      createButtonProps={{ children: 'Создать' }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Название" />
        <Table.Column
          dataIndex="sort"
          title="Сортировка"
          render={value => value ?? '-'}
        />
        <Table.Column
          dataIndex="popular"
          title="Популярность"
          render={(value?: boolean) =>
            typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : '-'
          }
        />
        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {record?.children?.length && (
                <EditButton hideText size="small" recordItemId={record.id} />
              )}
              <ShowButton hideText size="small" recordItemId={record.id} />
              {record?.children?.length && (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
