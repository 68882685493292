import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  BooleanField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { List, Typography } from 'antd'
import { ArtistsCategoriesRo } from '@/services/data-contracts'

const { Title } = Typography

export const ArtistsCategoriesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ArtistsCategoriesRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      canEdit={!!record?.children?.length}
      canDelete={!!record?.children?.length}
      title="Просмотр Категории Артистов"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      {record && (
        <>
          <Title level={5}>ID</Title>
          <NumberField value={record?.id ?? ''} />
          <Title level={5}>Название</Title>
          <TextField value={record?.name ?? ''} />
          {record?.children?.length ? (
            <>
              <Title level={5}>Сортировка</Title>
              <NumberField value={record?.sort ?? 0} />
              <Title level={5}>Популярность</Title>
              <BooleanField value={record?.popular ?? 0} />
              <Title level={5} style={{ marginTop: 20 }}>
                Дочернии категории
              </Title>
              <List
                size="small"
                bordered
                dataSource={record?.children}
                renderItem={item => (
                  <List.Item>
                    <a href={`/artists-categories/show/${item.id}`}>
                      {item.name}
                    </a>
                  </List.Item>
                )}
              />
            </>
          ) : null}
        </>
      )}
    </Show>
  )
}
