import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IResourceComponentsProps } from '@refinedev/core'
import { Edit, useForm, useSelect } from '@refinedev/antd'
import { Button, Form, Input, Select, Space } from 'antd'
import { Roles } from './enums'
import { RegionRoDto } from '@/services/data-contracts'
import { SettingOutlined } from '@ant-design/icons'

export const UsersEdit: React.FC<IResourceComponentsProps> = () => {
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const { formProps, saveButtonProps, onFinish } = useForm()

  const { selectProps: selectRegions } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const generatePassword = useCallback(() => {
    setPassword(uuidv4())

    setPasswordVisible(true)
  }, [])

  const handleOnFinish = useCallback(
    (values: any) => {
      const userDto = {
        ...values,
        id: undefined,
        ...(password && { newPassword: password }),
      }

      onFinish(userDto)
    },
    [onFinish, password],
  )

  return (
    <Edit saveButtonProps={saveButtonProps} title="Редактирование Пользователя">
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item label="ID" name={['id']}>
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item label="Почта" name={['email']}>
          <Input />
        </Form.Item>
        <Form.Item label="Роль" name={['roles']}>
          <Select
            options={Object.keys(Roles).map(roleNum => ({
              label: Roles[roleNum],
              value: +roleNum,
            }))}
          />
        </Form.Item>

        <Form.Item label="Регион" name={['region', 'id']}>
          <Select {...selectRegions} showSearch={false} placeholder="Регион" />
        </Form.Item>
        <Form.Item label="Новый пароль" name={['newPassword']}>
          <Space direction="horizontal">
            <Input.Password
              value={password}
              style={{ width: 400 }}
              onChange={e => setPassword(e.target.value)}
              placeholder="Введите новый пароль"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
            <Button onClick={generatePassword}>
              <SettingOutlined />
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Edit>
  )
}
