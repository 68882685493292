import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select } from 'antd'
import {
  CompilationsDto,
  CompilationsWithEventsRo,
  RegionRoDto,
} from '@/services/data-contracts'
import { useWatch } from 'antd/es/form/Form'
import useSelectProps from '@/hooks/useSelectProps'

export const CompilationsEditTop: React.FC<IResourceComponentsProps> = () => {
  const {
    onFinish,
    formProps,
    saveButtonProps,
    form,
    queryResult: { data: formResult },
  } = useForm<CompilationsWithEventsRo>({
    redirect: 'show',
  })

  const {
    selectProps: selectRegions,
    queryResult: { data },
  } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'key',
  })

  const region = useWatch('region', form)

  const dataEvents = formResult?.data.events?.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const {
    onChange: onChangeEvents,
    selectProps: selectEvents,
    options: optionsEvents,
    values: valuesEvents,
  } = useSelectProps({
    data: dataEvents,
    filters: [
      {
        field: 'region',
        operator: 'eq',
        value: data?.data?.find(({ id }) => id === region?.id)?.key || 'msk',
      },
    ],
    resource: 'events/select',
  })

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      events: valuesEvents?.map(({ value }) => value),
    } as CompilationsDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Редактирование Подбороки">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item label="Название" name={['name']}>
          <Input readOnly disabled placeholder="Например: Кино" />
        </Form.Item>
        <Form.Item label="Ключ" name={['key']}>
          <Input readOnly disabled placeholder="Например: slider" />
        </Form.Item>
        <Form.Item
          label="Регион"
          name={['region']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...selectRegions}
            showSearch={false}
            placeholder="Выберите регион"
          />
        </Form.Item>
        <Form.Item
          label="Мероприятия"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            {...selectEvents}
            onChange={onChangeEvents}
            value={valuesEvents as any}
            options={optionsEvents}
            mode="multiple"
            maxCount={10}
            placeholder="..."
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
