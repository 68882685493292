import React, { useState } from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Typography,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'

import locale from 'antd/locale/ru_RU'
import dayjs from 'dayjs'

import 'dayjs/locale/ru'
import { CategoryRo, RegionRoDto } from '@/services/data-contracts'

dayjs.locale('ru')

const { Text, Title } = Typography

export const EventsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm({
    redirect: 'show',
  })

  const [regions, setRegions] = useState<number>(null)
  const [venue, setVenue] = useState<number>(null)

  const { selectProps: selectRegions } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const { selectProps: selectCategories } = useSelect<CategoryRo>({
    resource: 'categories',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const { selectProps: selectVenues } = useSelect<CategoryRo>({
    resource: 'venues',
    pagination: { mode: 'server', pageSize: 30 },
    filters:
      regions !== null
        ? [
            {
              field: 'regionsList',
              operator: 'eq',
              value: regions,
            },
          ]
        : [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const { selectProps: selectHalls } = useSelect<CategoryRo>({
    resource: 'venues/halls',
    pagination: { mode: 'server', pageSize: 30 },
    filters:
      venue !== null
        ? [
            {
              field: 'venue',
              operator: 'eq',
              value: venue,
            },
          ]
        : [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const handleOnFinish = (values: any) => {
    onFinish(values)
  }

  return (
    <ConfigProvider locale={locale}>
      <Create
        saveButtonProps={saveButtonProps}
        title="Редактирование Мероприятия"
      >
        <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
          <Form.Item
            label="Название Мероприятия"
            name={['name']}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="..." />
          </Form.Item>
          <Form.Item
            label="Ссылка на Мероприятие"
            name={['url']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="..." />
          </Form.Item>
          <Form.Item label="Описание" name={['description']}>
            <MDEditor
              data-color-mode="light"
              commands={[underline, ...getCommands()]}
            />
          </Form.Item>

          <Card style={{ marginBottom: 20 }}>
            <Title level={5}>Расписание</Title>

            <Form.List name="dates">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Text>Начало: </Text>
                      <Form.Item
                        {...restField}
                        style={{ width: 200 }}
                        name={[name, 'date1']}
                        rules={[
                          {
                            required: true,
                            message: 'Поле должно быть заполнено',
                          },
                        ]}
                      >
                        <DatePicker showTime />
                      </Form.Item>
                      -<Text>Конец: </Text>
                      <Form.Item
                        {...restField}
                        style={{ width: 200 }}
                        name={[name, 'date2']}
                        rules={[
                          {
                            required: true,
                            message: 'Поле должно быть заполнено',
                          },
                        ]}
                      >
                        <DatePicker showTime />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Добавить
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Card>

          <Form.Item
            label="Возрастное ограничение"
            name={['age']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Одно из: 0+; 6+; 12+; 16+; 18+;" />
          </Form.Item>

          <Form.Item
            label="Минимальная цена"
            name={['price_min']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber
              style={{ width: 200 }}
              formatter={value =>
                `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              placeholder="..."
            />
          </Form.Item>
          <Form.Item
            label="Максимальная цена"
            name={['price_max']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber
              style={{ width: 200 }}
              formatter={value =>
                `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              placeholder="..."
            />
          </Form.Item>
          <Form.Item
            label="Электронные билеты"
            name={['eticket']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber style={{ width: 200 }} placeholder="..." />
          </Form.Item>
          <Form.Item
            label="Доступные билеты"
            name={['available_tickets']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber style={{ width: 200 }} placeholder="..." />
          </Form.Item>
          <Form.Item
            label="Язык"
            name={['language']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                { value: '-', label: '-' },
                { value: 'ENG', label: 'ENG' },
                { value: 'RU', label: 'RU' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Город"
            name={['city']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="..." />
          </Form.Item>
          <Form.Item
            label="Категория"
            name={['categories']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select {...selectCategories} showSearch placeholder="..." />
          </Form.Item>

          <Form.Item
            label="Регион"
            name={['regions']}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              {...selectRegions}
              showSearch
              placeholder="..."
              onChange={value => {
                setRegions(value as unknown as number)
              }}
            />
          </Form.Item>

          {regions !== null && (
            <>
              <Form.Item
                label="Площадка"
                name={['venues']}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  {...selectVenues}
                  showSearch
                  placeholder="..."
                  onChange={value => {
                    setVenue(value as unknown as number)
                  }}
                />
              </Form.Item>

              {venue !== null && (
                <Form.Item
                  label="Зал"
                  name={['halls']}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select {...selectHalls} showSearch placeholder="..." />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Create>
    </ConfigProvider>
  )
}
