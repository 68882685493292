import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { Card, Image, Skeleton, Typography } from 'antd'
import { CompilationsWithEventsRo } from '@/services/data-contracts'

const { Title } = Typography

export const CompilationsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<CompilationsWithEventsRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Подбороки"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      <Title level={5}>ID</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>{'Регион'}</Title>
      <TextField value={record?.region.name ?? ''} />
      <Title level={5}>{'Название'}</Title>
      <TextField value={record?.name ?? ''} />
      <Title level={5}>{'Ключ'}</Title>
      <TextField value={record?.key ?? ''} />
      <Title level={5}>{'Мероприятия'}</Title>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: 15,
          marginBottom: 25,
          flexWrap: 'wrap',
        }}
      >
        {record?.events.map(event => (
          <a href={event.url} target="_blank" rel="noopener noreferrer">
            <Card
              style={{ width: 350 }}
              cover={
                <img
                  alt={event.name}
                  src={event.horizontal_poster || event.poster}
                />
              }
            >
              <Card.Meta title={event.name} description={event.url} />
            </Card>
          </a>
        ))}
      </div>
      <Title level={5}>{'Постер/Картинка'}</Title>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: 15,
          marginBottom: 25,
          flexWrap: 'wrap',
        }}
      >
        {record?.poster?.length ? (
          record?.poster.map(poster => (
            <Image width={200} src={poster.location ?? ''} />
          ))
        ) : (
          <Skeleton.Image />
        )}
      </div>
    </Show>
  )
}
