import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import {
  Form,
  Input,
  Upload,
  Typography,
  Select,
  Space,
  Button,
  Card,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  HallsDto,
  OrganizationsDto,
  RegionRoDto,
  VenueDto,
} from '@/services/data-contracts'
import MDEditor, { getCommands } from '@uiw/react-md-editor'
import underline from '@/services/md/underline-command'
import { UploadChangeParam } from 'antd/es/upload'

const { Title } = Typography

export type VenueCreateObj = {
  address: string
  description?: string
  lat?: string
  lon?: string
  metaDescription?: string
  metaKeywords?: string
  metaTitle?: string
  name: string
  poster?: UploadChangeParam
  region?: number[]
  url?: string
  halls?: HallsDto[]
}

export const VenuesCreate: React.FC<IResourceComponentsProps> = () => {
  const { onFinish, formProps, saveButtonProps } = useForm<OrganizationsDto>()

  const { selectProps: selectRegions } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'id',
  })

  const handleOnFinish = (values: any) => {
    const formValues = values as VenueCreateObj

    onFinish({
      ...values,
      halls: formValues.halls?.map(({ address, name }) => ({
        address,
        name,
      })),
      region: { id: formValues.region },
      poster: formValues.poster?.file?.response?.location || '',
    } as VenueDto)
  }

  return (
    <Create saveButtonProps={saveButtonProps} title="Создание Площадки">
      <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
        <Form.Item
          label="Название"
          name={['name']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Поле должно быть заполнено',
            },
          ]}
        >
          <Input placeholder="Название" />
        </Form.Item>
        <Form.Item
          label="Адрес"
          name={['address']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Поле должно быть заполнено',
            },
          ]}
        >
          <Input placeholder="Адрес" />
        </Form.Item>
        <Form.Item
          label="Сторонняя ссылка"
          name={['url']}
          rules={[
            {
              whitespace: false,
            },
          ]}
        >
          <Input placeholder="Сторонняя ссылка" />
        </Form.Item>
        <Form.Item label="Описание" name={['description']}>
          <MDEditor
            data-color-mode="light"
            commands={[underline, ...getCommands()]}
          />
        </Form.Item>

        <Form.Item label="Превью площадки" name="poster">
          <Upload
            maxCount={1}
            action={`${process.env.API_URL}/uploads`}
            headers={{
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            }}
            listType="picture-card"
            onPreview={file => window.open(file?.response?.location)}
          >
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Широта"
          name={['lat']}
          rules={[
            {
              required: false,
              whitespace: false,
            },
          ]}
        >
          <Input placeholder="Широта" />
        </Form.Item>

        <Form.Item
          label="Долгота"
          name={['lon']}
          rules={[
            {
              required: false,
              whitespace: false,
            },
          ]}
        >
          <Input placeholder="Долгота" />
        </Form.Item>

        <Form.Item
          label="Регион"
          name={['region']}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select {...selectRegions} showSearch={false} placeholder="Регион" />
        </Form.Item>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Залы</Title>

          <Form.List name="halls">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Название Зала" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'address']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Адрес Зала" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Контакты</Title>

          <Form.List name="contacts">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(field => (
                  <Form.Item required={true} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Контактная информация"
                        style={{ width: '60%' }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Социальные сети</Title>

          <Form.List name="socials">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      style={{ width: 100 }}
                      name={[name, 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: 'VK', value: 'vk' },
                          { label: 'Telegram', value: 'tg' },
                          { label: 'OK', value: 'ok' },
                          { label: 'YouTUbe', value: 'youtube' },
                          { label: 'WWW', value: 'web' },
                        ]}
                        placeholder="Тип социальной сети"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ width: 300 }}
                      name={[name, 'link']}
                      rules={[
                        {
                          required: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                    >
                      <Input placeholder="Ссылка" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Параметры площадки</Title>

          <Form.List name="parameters">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        { required: true, message: 'Не введено Название' },
                      ]}
                    >
                      <Input placeholder="Название(Площадь)" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[
                        { required: true, message: 'Не введено Значение' },
                      ]}
                    >
                      <Input placeholder="Значение(4 000 м2)" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>
        <Card style={{ marginBottom: 20 }}>
          <Title level={5}>Дополнительная информация</Title>

          <Form.List name="moreInfo">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(field => (
                  <Form.Item required={true} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Поле должно быть заполнено',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Дополнительная информация"
                        style={{ width: '60%' }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Добавить
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Title level={5}>Мета</Title>

        <Form.Item label="Заголовок" name={['metaTitle']}>
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name={['metaDescription']}>
          <Input />
        </Form.Item>
        <Form.Item label="Тэги" name={['metaKeywords']}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
