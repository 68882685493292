import React from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  HttpError,
} from '@refinedev/core'
import { useTable, List, ShowButton } from '@refinedev/antd'
import { Table, Space } from 'antd'
import { NewsAdminDataRo } from '@/services/data-contracts'

export const CategoriesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<NewsAdminDataRo, HttpError>()

  return (
    <List title="Список Категорий Кассира">
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Навзвание" />
        <Table.Column dataIndex="kassirId" title="ID Кассира" />
        <Table.Column
          dataIndex="parentKassirId"
          title="Родительский ID Кассира"
        />

        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
