import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  Show,
  NumberField,
  TextField,
  MarkdownField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from '@refinedev/antd'
import { Card, Image, Typography } from 'antd'
import { NewsRo } from '@/services/data-contracts'

const { Title } = Typography

export const NewsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<NewsRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Новости"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      <Card style={{ marginBottom: 25 }}>
        <Title level={5}>ID</Title>
        <NumberField value={record?.id ?? ''} />
        <Title level={5}>Название новости</Title>
        <TextField value={record?.name ?? ''} />
        <Title level={5}>Ресурс</Title>
        <TextField value={record?.source ?? ''} />
      </Card>

      {record?.preview?.location && (
        <Card style={{ marginBottom: 25 }}>
          <Image width={500} src={record.preview.location ?? ''} />
        </Card>
      )}

      <Card style={{ marginBottom: 25 }}>
        <Title level={3}>Превью описание</Title>
        <MarkdownField value={record?.previewDescription} />

        <Title level={3}>Полное описание</Title>
        <MarkdownField value={record?.description} />
      </Card>

      <Card>
        <Title level={3}>Мета</Title>

        <Title level={5}>Заголовок</Title>
        <TextField value={record?.metaTitle ?? ''} />

        <Title level={5}>Описание</Title>
        <TextField value={record?.metaDescription ?? ''} />

        <Title level={5}>Тэги</Title>
        <TextField value={record?.metaKeywords ?? ''} />
      </Card>
    </Show>
  )
}
