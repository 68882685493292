import React from 'react'
import { IResourceComponentsProps, useApiUrl, useCustom } from '@refinedev/core'
import { MarkdownField, Show } from '@refinedev/antd'
import { Button, Typography } from 'antd'
import { PolicyDataRo } from '@/services/data-contracts'
import { Card } from 'antd'
import { format } from 'date-fns-tz'
import { ru } from 'date-fns/locale'

const { Title } = Typography

export const PolicyShow: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl()

  const { data, isLoading } = useCustom<PolicyDataRo>({
    url: `${apiUrl}/policy`,
    method: 'get',
  })

  const info = data?.data

  const date =
    info?.updatedAt &&
    format(new Date(info?.updatedAt), 'd MMMM yyyy', { locale: ru })

  return (
    <Show
      title="Политика конфинденциальности"
      isLoading={isLoading}
      goBack={false}
      headerButtons={() => (
        <>
          <Button href="/policy/edit" type="primary">
            Редактировать
          </Button>
        </>
      )}
    >
      <Card style={{ marginBottom: 25 }}>Редакция от {date}</Card>
      {info?.blocks?.map(({ id, order, name, description }) => (
        <Card style={{ marginBottom: 25 }} key={id}>
          <Title level={5}>
            {order}. {name}
          </Title>
          <MarkdownField value={description} />
        </Card>
      ))}
    </Show>
  )
}
